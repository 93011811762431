<template>
  <page-content>
    <page-title heading="การออกใบประกาศหลังเรียนจบ">
      <template v-slot:right> </template>
    </page-title>

    <b-row>
      <b-col cols="12">
        <div class="d-flex flex-row flex-wrap">
          <div class="col-3 px-2">
            <label class="font-weight-bold ft-s-14" for="fiscalYearFrom"
              >เริ่มปีการศึกษา</label
            >

            <b-form-input
              id="fiscalYearFrom"
              v-model="fiscalYearFrom"
            ></b-form-input>
          </div>
          <b-col cols="3" class="px-2">
            <label class="font-weight-bold ft-s-14" for="termFrom"
              >ภาคเรียน</label
            >

            <v-select
              id="termFrom"
              v-model="termFrom"
              :options="terms"
            ></v-select>
          </b-col>

          <div class="col-3 px-2">
            <label class="font-weight-bold ft-s-14" for="fiscalYearTo"
              >ถึงปีการศึกษา</label
            >

            <b-form-input v-model="fiscalYearTo"></b-form-input>
          </div>
          <b-col cols="3" class="px-2">
            <label class="font-weight-bold ft-s-14" for="termTo"
              >ภาคเรียน</label
            >

            <v-select id="termTo" v-model="termTo" :options="terms"></v-select>
          </b-col>
        </div>
      </b-col>

      <b-col cols="12" class="mt-2">
        <filter-master
          v-model="formfilter"
          :m-headquarter-id="
            !authUser.canAccessAllHeadQuarters ? authUser.mHeadquarterId : null
          "
          :m-division-id="
            !authUser.canAccessAllDivisions ? authUser.mDivisionId : null
          "
          :m-station-id="
            !authUser.canAccessAllStations ? authUser.mStationId : null
          "
          :disabledInput="{
            headQuarter:
              !authUser.canAccessAllHeadQuarters &&
              authUser.mHeadquarterId !== null,
            division:
              !authUser.canAccessAllDivisions && authUser.mDivisionId !== null,
            station:
              !authUser.canAccessAllStations && authUser.mStationId !== null,
          }"
          :hiddenInput="{ school: true, schoolClass: true }"
        >
        </filter-master>
      </b-col>

      <b-col cols="12" class="mt-2">
        <filter-date-between v-model="filterDateBetween"></filter-date-between>
      </b-col>
      <!-- 
      <b-col cols="12" class="mt-2">
        <div class="d-flex flex-row flex-wrap align-items-end">
          <b-col cols="6" md="4" class="px-2">
            <b-form-group class="mb-0">
              <b-input-group class="input-group-seamless">
                <b-form-input
                  placeholder="ค้นหารายชื่อครูหรือโรงเรียน"
                  v-model="keyword"
                  @keyup.enter="onKeywordSearched"
                ></b-form-input>

                <b-input-group-text slot="prepend">
                  <font-awesome-icon class="mx-auto" icon="search" />
                </b-input-group-text>

                <b-input-group-text v-show="keyword.length" slot="append">
                  <a href="javascript:void(0)" @click="clearKeyword">
                    <font-awesome-icon class="mx-auto" icon="window-close" />
                  </a>
                </b-input-group-text>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="2" class="px-2">
            <b-button
              variant="primary"
              block
              size="sm"
              @click="onKeywordSearched"
              >ค้นหา</b-button
            >
          </b-col>
        </div>
      </b-col> -->
    </b-row>

    <teaching-dare-table
      ref="table"
      class="mt-4"
      :fields="fields"
      :params="TableParams"
      action-label=""
      :show-action="false"
      :see-detail="true"
      :show-delete="false"
      @seeDetail="onSeeDetail"
      @delete="onDelete"
      :isReport="true"
    />
  </page-content>
</template>

<script>
import vSelect from "vue-select";
import PageContent from "../../components/layout/PageContent";
import PageTitle from "../../components/layout/PageTitle";
import TeachingDareTable from "../../components/table/TeachingDare";
import FilterMaster from "../../components/form/FilterMaster";
import FilterDateBetween from "../../components/form/FilterDateBetween";
import { Auth } from "../../models";

export default {
  components: {
    vSelect,
    PageContent,
    PageTitle,
    TeachingDareTable,
    FilterMaster,
    FilterDateBetween,
  },

  data() {
    return {
      keyword: "",
      terms: ["1", "2"],
      fiscalYearFrom: "",
      termFrom: "",
      fiscalYearTo: "",
      termTo: "",
      formfilter: {},
      filterDateBetween: {
        startDate: null,
        endDate: null,
      },
      deleteData: {},
      transactionId: null,
      isExporting: false,
      showModal: false,
      showModalDelete: false,
      export_fields: {
        หมายเลข: "number",
        ชั่วโมงการสอน: "total_time",
        จำนวนเงิน: "total_money",
        วันที่ขอ: "created_at",
      },
    };
  },

  computed: {
    authUser() {
      return Auth.user();
    },

    fields() {
      const fields = [
        // { key: "order", label: "ลำดับ" },
        { key: "station", label: "สถานี", sortable: true },
        { key: "school", label: "โรงเรียน", sortable: true },
        { key: "amphur", label: "อำเภอ", sortable: true },
        { key: "province", label: "จังหวัด", sortable: true },
        { key: "yearTerm", label: "ปีการศึกษา", sortable: true },
        { key: "classRoom", label: "ห้องเรียน", sortable: true },
        // { key: "user_name", label: "ชื่อ-นามสกุล" },
        {
          key: "seeDetail",
          label: "พิมพ์ใบประกาศทั้งหมด",
          class: "text-center",
        },
      ];

      return fields;
    },

    TableParams() {
      const { filterDateBetween: dateParams, formfilter } = this;

      const params = {};

      if (!this.authUser.isAdminType) {
        this.$set(params, "userProfileId", this.authUser.id);
      }

      return {
        ...formfilter,
        ...params,
        ...dateParams,
      };
    },
  },

  methods: {
    onKeywordSearched() {
      this.$set(this.formfilter, "fiscalYearFrom", this.fiscalYearFrom);
      this.$set(this.formfilter, "fiscalYearTo", this.fiscalYearTo);
      this.$set(this.formfilter, "termFrom", this.termFrom);
      this.$set(this.formfilter, "termTo", this.termTo);
    },

    clearKeyword() {
      this.keyword = "";
      this.$delete(this.formfilter, "fiscalYearFrom");
      this.$delete(this.formfilter, "fiscalYearTo");
      this.$delete(this.formfilter, "termFrom");
      this.$delete(this.formfilter, "termTo");
    },

    onCreateSuccess() {
      this.$refs.table.refresh();
    },

    onSeeDetail() {},

    onDelete(item) {
      this.deleteData = { ...item };
      this.showModalDelete = true;
    },

    onDeleteSuccess() {
      this.$refs.table.refresh();
    },
  },
};
</script>
